import React from 'react'

import './Feature.css';

function Feature({ icon: Icon, title, text }) {
  return (
    <div className="feature-card flex items-center">
    <div className="mr-4 p-1">
      <Icon style={{ width: '75px' }} />
    </div>

    <div>
      <h3 className="text-lg feature-title">{title}</h3>
      <p className="text-sm feature-text">{text}</p>
    </div>
  </div>
  )
}

export default Feature
