import React from "react";
import { Container, Carousel } from "react-bootstrap";

import user_testing from "../images/user_testing.png";

import "./PartnerReview.css";

function PartnerReviews() {
  const partnerReviews = [
    {
      quote:
        "This will allow residents to be more comfortable communicating with care providers and an increased quality of life.",
      title: "Vice President, Sienna Senior Living",
    },
    {
      quote:
        "I see this placed in all our facilities–in the nursing station for pain management and assessments, dietary departments.  Even communicating with other residents.",
      title: "Executive Director, The Rekai Centres",
    },
    {
      quote:
        "The device is easy to use, and I like that I don’t need to talk into a phone or worry about it being too complicated. It also helps with more than just the basics—now I can have deeper conversations without feeling misunderstood.",
      title: "Resident, O'Neill Centre",
    },
    {
      quote:
        "The translation device has been a game-changer. We no longer have to rely on communication cards that are limited to basic needs. This device allows for more meaningful conversations with residents, especially those with cognitive impairments or hearing difficulties.",
      title: "Staff, O'Neill Centre",
    },
    {
      quote:
        "We can't wait to use it, and participating in these discussions about how the device should look and what buttons and symbols it should have, has been so much fun. Such a great experience!",
      title: "Resident, Ukrainian Canadian Care Centre",
    },
  ];

  return (
    <>
      <Container className="py-5">
        {/* Partner Reviews Section */}
        <div className="text-center text-2xl font-semibold mt-4 header">
          Partner Reviews
        </div>
        <div className="py-3">
          <div className="row align-items-center">
            {/* Left side image */}
            <div className="col-md-6 text-center">
              <img
                src={user_testing}
                alt="user-testing"
                className="img-fluid"
              />
            </div>

            {/* Right side carousel */}
            <div className="col-md-6">
              <Carousel data-bs-theme="dark" className="carousel-card">
                {partnerReviews.map((review, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex flex-column align-items-center text-center reviews">
                      <blockquote className="blockquote quote">
                      <p className="fst-italic p-quote">“{review.quote}”</p>                      </blockquote>
                      <footer className="blockquote-footer">
                        <strong>{review.title}</strong> <br />
                      </footer>
                    </div>
                  </Carousel.Item>
                  
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default PartnerReviews;
