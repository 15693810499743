import React from 'react';
import { Container } from 'react-bootstrap';

import uoft_logo from "../images/uoft-logo.png";

import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="py-4">
      <Container>
        <div className="d-flex justify-content-center align-items-center">
          <img src={uoft_logo} className="w-2/6	" alt="uoft_logo" />
        </div>
        <p className="copyright-text text-center mb-0 p-2">&copy; {currentYear} aiko. proprietary</p>
      </Container>
    </footer>
  );
};

export default Footer;
