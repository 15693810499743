import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import { FaLinkedin, FaEnvelope } from "react-icons/fa";

import "./ContactUs.css";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    const request = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        mode: "cors",
      },
      body: JSON.stringify({
        subject: formData.subject,
        message: formData.message,
        email: formData.email,
        name: formData.name,
      }),
    };
    fetch("http://127.0.0.1:5000/api/contact", request)
      .then((response) => alert("Success: Your message has been sent!"))
      .catch((error) =>
        alert(
          "Error: Failed to send message. Please reach out to the Aiko Team at contact@aikotranslations.com"
        )
      );
    // reset the form fields after submission
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <div className="contact-us">
        <div className="w-full text-center px-5 pt-5">
          <h2 className="font-bold text-white pt-5 contact-header mb-3">
            Interested in collaborating with us?
          </h2>
          <h4 className="text-white interest-text">
            We are opening up our partnerships with long-term care homes and professionals
          </h4>
          <h4 className="text-white message-text mb-5">Send Us a Message!</h4>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center mb-3 mb-md-0">
              <FaLinkedin className="text-white mr-2" size={30} />
              <a
                href="https://www.linkedin.com/company/agiletranslations/"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-text"
              >
                Aiko Translations
              </a>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <FaEnvelope className="text-white mr-2" size={30} />
            <a
                href="mailto:contact@aikotranslations.com"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-text"
              >
                contact@aikotranslations.com
              </a>
          </Col>
        </Row>
          
        </div>

        <Container>
          <Form className="p-5" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formName">
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formSubject">
                  <Form.Control
                    type="text"
                    placeholder="Your Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group controlId="formMessage">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Your Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
}

export default ContactUs;
