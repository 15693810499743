import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import logo from '../images/aiko.png';

import './NavBar.css';

const NavBar = ({ ourProductRef, contactUsRef }) => {

  return (
    <>
      <Navbar className="navbar" expand="lg">
        <Navbar.Brand className="bg-white" href="#home">
          <img
            alt=""
            src={logo}
            width="130"
            className="navbar-logo d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link
              className="nav-link navbar-item"
              activeClass="active"
              to="our-product"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => ourProductRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Our Product
            </Link>
            <Link
              className="nav-link navbar-item"
              activeClass="active"
              to="contact-us"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => contactUsRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
