// import './App.css';
import React, { useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Footer from "./components/Footer";

function App() {
  const ourProductRef = useRef(null);
  const contactUsRef = useRef(null);

  return (
    <Router>
      {/* <NavBar /> */}
      <NavBar
        ourProductRef={ourProductRef}
        contactUsRef={contactUsRef}
      />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route
          path="/"
          element={<Home
                      ourProductRef={ourProductRef}
                      contactUsRef={contactUsRef}
                    />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
