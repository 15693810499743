import React from "react";
import { Container } from "react-bootstrap";
import Feature from "./Feature";
import main_image from "../images/main-image.png";
import cad from "../images/cad.png";

import { ReactComponent as LTCare } from "../images/icons/long-term-care.svg";
import { ReactComponent as HomeCare } from "../images/icons/home-care.svg";

import { ReactComponent as AutoLanguageDetection } from "../images/icons/automated-language-detection.svg";
import { ReactComponent as Customizable } from "../images/icons/customizable.svg";
import { ReactComponent as ImplementationServices } from "../images/icons/implementation-services.svg";
import { ReactComponent as PrivacyAssurance } from "../images/icons/privacy-assurance.svg";
import { ReactComponent as SpeechImpairmentRecog } from "../images/icons/speech-impairment-recognition.svg";
import { ReactComponent as TactileInterface } from "../images/icons/tactile-interface.svg";

import "./OurProduct.css";

function OurProduct() {
  const productData = [
    {
      icon: LTCare,
      title: "Long-term Care Homes",
      text: "Translates daily conversations between care providers, residents and program coordinators for all long-term care settings.",
    },
    {
      icon: HomeCare,
      title: "Assistive & Home Care",
      text: "Facilitates medical conversations between care providers and residents.",
    },
  ];

  const featureData = [
    {
      icon: AutoLanguageDetection,
      title: "Automatic Language Detection",
      text: "Recognizes the language spoken by users upon initial interaction.",
    },
    {
      icon: SpeechImpairmentRecog,
      title: "Speech Impairment Recognition",
      text: "Leverages artificial intelligence to identify and understand speech patterns of speech impairments.",
    },
    {
      icon: Customizable,
      title: "Customizable",
      text: "Personalizes device outputs and interaction styles for more accessible experiences.",
    },
    {
      icon: TactileInterface,
      title: "Tactile Interface",
      text: "Accomodates individuals with physical impairments.",
    },
    {
      icon: ImplementationServices,
      title: "Implementation Services",
      text: "Provides on-boarding training for care providers, technology, device maintenance, and IT integration.",
    },
    {
      icon: PrivacyAssurance,
      title: "Privacy Assurance",
      text: "Ensuring confidentiality and privacy for all users and their translations.",
    },
  ];

  return (
    <>
      <img src={main_image} alt="product" />

      <div style={{ backgroundColor: "#F6F5FB" }}>
        <Container>
          <div className="flex items-center justify-center p-10 text-xl">
            <div className="text-center description">
              A real-time, care-centered, hands-free translation solution for
              130+ languages.
            </div>
          </div>

          <div className="text-center text-2xl font-semibold mt-3 pb-3 header">
            Our Product Serves
          </div>
          <div className="flex flex-col lg:flex-row p-10">
            {/* Left side CAD */}
            <div className="w-full lg:w-1/2 bg-svg-container relative mt-8">
              <img src={cad} alt="product" className="cad-image w-full h-auto relative z-10" />
            </div>

            {/* Right side product items */}
            <div className="w-full lg:w-1/2 flex flex-col justify-center">
              {productData.map((item, index) => (
                <div
                  key={index}
                  className="product-card flex flex-col items-center p-5"
                >
                  <item.icon style={{ width: "100px", height: "100px" }} />
                  <h3 className="text-lg product-title mt-3">{item.title}</h3>
                  <p className="text-sm product-text mt-2 text-center">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center text-2xl font-semibold mt-4 header">
            aiko. Translations Benefits
          </div>
          <div className="feature-list grid grid-cols-1 md:grid-cols-2 p-3">
            {featureData.map((item, index) => (
              <Feature
                key={index}
                icon={item.icon}
                title={item.title}
                text={item.text}
              />
            ))}
          </div>

          <div className="flex items-center justify-center p-10 text-xl">
            <div className="text-center reach-out">
              Reach out to us for more information on how we can tailor our
              translation solution to your care setting.
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default OurProduct;
