import React from "react";
import OurProduct from "./OurProduct";
import ContactUs from "./ContactUs";
import PartnerReviews from "./PartnerReviews";

function Home({ ourProductRef, contactUsRef }) {
  return (
    <>
      <div ref={ourProductRef} id="our-product">
        <OurProduct ref={ourProductRef} id="our-product" />
        <PartnerReviews />
      </div>
      <div ref={contactUsRef} id="contact-us">
        <ContactUs ref={contactUsRef} id="contact-us" />
      </div>
    </>
  );
}

export default Home;
